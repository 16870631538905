<template>
  <div>
    <div
      class="d-flex no-gutters align-items-center justify-content-between"
      style="min-height: 35px"
    >
      <div>
        <h4 :class="['mr-sm-4 mb-0 header-tablepage ']">
          {{ title }}
        </h4>
        <slot name="filter-title"></slot>
      </div>
      <div v-if="hasFilter">
        <b-button
          variant="filter"
          @click="visible = !visible"
          class="btn-filter"
        >
          <font-awesome-icon icon="filter" class="pointer icon" />
          <span class="d-none d-md-block ml-2">กรองข้อมูล</span>
        </b-button>
      </div>
    </div>
    <b-sidebar
      id="sidebar-filter"
      v-model="visible"
      title="กรองข้อมูล"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-filter-title"
      :class="[{ 'side-bar-visible': visible }]"
      @hidden="visible = false"
    >
      <div class="py-3">
        <slot name="filter-option"></slot>
      </div>
      <template #footer="{ hide }">
        <div
          class="d-flex justify-content-between shadow align-items-center w-100"
        >
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light"
              @click.prevent="clear"
            >
              ล้างตัวกรอง
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="search"
              block
              variant="light"
              class="text-body btn-main"
            >
              ค้นหา
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    filter: {
      required: false,
      type: Object
    },
    placeholder: {
      required: false,
      type: String
    },
    hasFilter: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    clear() {
      this.visible = false;
      this.$emit('clear');
    },
    search() {
      this.visible = false;
      this.$emit('search', this.filter);
    },
    handleSearch(e) {
      if (e.type === 'keyup') {
        if (e.keyCode === 13) {
          this.$emit('search', this.filter);
        }
      } else if (e.type === 'click') {
        this.$emit('search', this.filter);
      }
    }
    // exportExcel() {
    //   this.$emit("confirmExport");
    // },
    // sidebarFilter() {
    //   this.$emit("sidebar");
    // },
  }
};
</script>

<style lang="scss" scoped>
.btn-filter {
  width: auto;
  font-size: 14px;
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #000000 !important;
  svg {
    color: #000000 !important;
    font-size: 12px;
  }
  svg.arrow {
    color: var(--primary-color) !important;
  }
}
// .btn-search {
//   width: 125px;
//   color: #fff;
//   background-color: var(--primary-color);
//   border: 1px solid #fff;
//   padding: 6px 20px;
//   border-radius: 20px;
// }
// .btn-clear-filter {
//   text-decoration: underline;
//   padding: 6px 20px;
// }
.header-tablepage {
  color: var(--primary-color) !important;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
input.search-bar {
  box-shadow: inset 0 1px 2px hsl(0deg 0% 7% / 10%);
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

::v-deep .card {
  margin: 0 !important;
}

.btn-filter {
  display: flex;
  align-items: center;
  span,
  .icon {
    color: var(--primary-color) !important;
  }
  height: 35px;
  min-width: 35px;
}

::v-deep .b-sidebar-header {
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 16px;
}

::v-deep .b-sidebar {
  .b-sidebar-header {
    justify-content: space-between;
    flex-direction: row-reverse;
    .close {
      margin: 0;
      color: white !important;
    }
  }
}

::v-deep .b-sidebar-footer {
  display: flex;
  .btn {
    width: 100%;
    border: 0;
    border-radius: 0;
  }
  .btn-main {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
}

.w-100 {
  width: 100%;
}
</style>
